import React from "react";
import { Button } from "@nextui-org/react";
const LogoutButton = () => {
    const handleLogout = () => {


        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login'; // Redirigir al usuario a la página de inicio de sesión
    };

    return (

        <Button color="danger" onClick={handleLogout}>
            Cerrar sesión
        </Button>
    );
};

export default LogoutButton;