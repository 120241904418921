
import React, { useState, useEffect } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Select, SelectItem, Input, Link } from "@nextui-org/react";
import { API } from "../../services/api";
import axios from "axios";
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import 'dayjs/locale/es';



export default function CitaFormulario({ isOpen, onOpen, onOpenChange, onCitaSolicitada, selectedEvent }) {

    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [celular, setCelular] = useState('');
    const [horario, setHorario] = useState('');
    const [podologo, setPodologo] = useState('');

    const [eventos, setEventos] = useState([]);
    const [podologos, setPodologos] = useState([]);

    const handleNombreChange = (e) => {
        setNombre(e.target.value);
    };
    const handleApellidosChange = (e) => {
        setApellidos(e.target.value);
    };
    const handleCelularChange = (e) => {
        setCelular(e.target.value);
    };
    const handleHorarioChange = (e) => {
        setHorario(e.target.value);
    };
    const handlePodologoChange = (e) => {
        setPodologo(e.target.value);
    };


    useEffect(() => {
        inicio();
    }, []);

    const inicio = () => {
        const fechaActual = dayjs();
        const fechaLimite = fechaActual.add(2, 'week'); // Agregar dos semanas a la fecha actual
        const fechaInicioDelDia = fechaActual.startOf('day'); // Obtener el comienzo del día actual
        const fechaFinDelDia = fechaActual.endOf('day'); // Obtener el final del día actual

        axios.get(`${API}eventos/`)
            .then(res => {
                const eventosDelDiaYProximasDosSemanas = res.data.filter(evento =>
                    dayjs(evento.start).isBetween(fechaInicioDelDia, fechaLimite, null, '[]')
                ).map(evento => {
                    if (!evento.ocupado) {
                        return {
                            ...evento,
                            fechaFormato: `${evento.title} - ${dayjs(evento.start).locale('es').format('dddd, D [de] MMMM, HH:mm')} - ${dayjs(evento.end).format('HH:mm')}`
                        };
                    }
                    return null;
                }).filter(evento => evento !== null);
                eventosDelDiaYProximasDosSemanas.sort((a, b) => dayjs(a.start) - dayjs(b.start));

                setEventos(eventosDelDiaYProximasDosSemanas);
            })
            .catch(error => {
                console.error(error.response.status);
            });


        axios.get(`${API}podologos/`)
            .then(res => {
                setPodologos(res.data);
            })
            .catch(error => {
                console.error(error);
            });


    };

    const handleErrorCita = () => {
        Swal.fire({
            icon: 'error',
            title: 'Oops, algo salió mal.',
            text: 'Recargue la página o póngase en contacto con uno de nuestros técnicos',

        })

        onOpenChange(false);
    };


    const handleErrorCitaEventoOcupado = () => {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Este horario ya está en uso. Por favor, seleccione un horario disponible.',
            showCancelButton: false, // Ocultar el botón de cancelar
            confirmButtonText: 'OK' // Cambiar el texto del botón de confirmación a "OK"
        }).then((result) => {
            // Si el usuario presiona el botón "OK", recargar la página
            if (result.isConfirmed) {
                window.location.reload();
            }
        });

        onOpenChange(false);

    }

    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!celular.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Campo obligatorio',
                text: 'Por favor, ingrese su número de teléfono celular.',
            });
            return; // Detener el proceso de solicitud de cita si el campo está vacío o solo contiene espacios en blanco
        }

        const eventoSeleccionado = eventos.find(evento => evento.id === horario);
        if (eventoSeleccionado && eventoSeleccionado.ocupado) {
            handleErrorCitaEventoOcupado();
            return;
        }

        const payload = {
            nombre,
            apellidos,
            celular,
            horario,
            
        };
        try {
            const response = await axios.post(`${API}citas/`, payload);
            if (response.status === 201) {
                const citaId = response.data.id; // Obtener el ID de la cita creada
                onCitaSolicitada(citaId);

                let citasIds = JSON.parse(localStorage.getItem('citasIds')) || [];
                citasIds.push(citaId);
                localStorage.setItem('citasIds', JSON.stringify(citasIds));
                
                handleSuccessCita(citaId);
                setNombre('');
                setApellidos('');
                setCelular('');
                setHorario('');
                
                onOpenChange(false);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
            }
        } catch (error) {
            console.error('Error al crear la cita:', error);
            handleErrorCita();

        }
    };

    const handleSuccessCita = async (citaId) => {
        try {
            const response = await axios.get(`${API}cita/${citaId}`);

            const citaSeleccionada = response.data;

            const fechaInicio = dayjs(citaSeleccionada.horario.start).locale('es').format('dddd, D [de] MMMM, HH:mm');
            const fechaFin = dayjs(citaSeleccionada.horario.end).format('HH:mm');

            Swal.fire({
                icon: 'success',
                title: 'Todo listo',
                html: `
                    <div >
                        <p style="margin: 10px 0;">La solicitud de su cita se ha realizado con éxito.</p>
                        <p style="margin: 10px 0;">Hora: ${fechaInicio} - ${fechaFin}</p>
                    </div>
                `,
                confirmButtonText: 'Cerrar',
                showConfirmButton: false,
                timer: 15000,
            })
            onOpenChange(false);
        } catch (error) {
            // Maneja cualquier error de la solicitud
            console.error("Error al obtener los datos de la cita:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al obtener los detalles de la cita.'
            });
        }
    }


    return (
        <>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="center"

            >
                <form action="">
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <ModalHeader className="flex flex-col gap-1 items-center justify-center">
                                    Sacar Cita
                                </ModalHeader>
                                <ModalBody>
                                    <Input
                                        autoFocus
                                        label="Nombre"
                                        value={nombre}
                                        onChange={handleNombreChange}
                                        type="text"
                                        variant="bordered"
                                    />
                                    <Input
                                        autoFocus
                                        label="Apellidos"
                                        value={apellidos}
                                        onChange={handleApellidosChange}
                                        type="text"
                                        variant="bordered"
                                    />
                                    <Input
                                        autoFocus
                                        label="Celular"
                                        value={celular}
                                        onChange={handleCelularChange}
                                        type="number"
                                        variant="bordered"
                                    />

                                   

                                    <Select
                                        id="horario"
                                        name="horario"
                                        value={selectedEvent ? selectedEvent.id : ''}
                                        label="Confirmar horario"
                                        onChange={handleHorarioChange}
                                        className="max-w-xl" >
                                        {selectedEvent && (
                                            <SelectItem key={selectedEvent.id} value={selectedEvent.id}>
                                                {`${selectedEvent.title} - ${dayjs(selectedEvent.start).locale('es').format('dddd, D [de] MMMM, HH:mm')} - ${dayjs(selectedEvent.end).format('HH:mm')}`}
                                            </SelectItem>
                                        )}
                                    </Select>



                                </ModalBody>
                                <ModalFooter className="flex items-center justify-center">
                                    <Button color="success" className="text-white" onClick={handleSubmit} >
                                        Solicitar
                                    </Button>
                                    <Button color="danger" variant="flat" onPress={onClose}>
                                        Cancelar
                                    </Button>

                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </form>
            </Modal>
        </>
    );
}
