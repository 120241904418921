

import React, { useState } from 'react';

import { TabMenu } from 'primereact/tabmenu';

import Agenda from './Agenda';
import Recordatorio from './Recordatorios';
import Citas from './Citas';
import Header from '../components/Static/Header';

export default function Admin() {

    const items = [
        { label: 'Citas', icon: 'pi pi-fw pi-users' },
        { label: 'Agenda', icon: 'pi pi-book' },
        { label: 'Recordatorios', icon: 'pi pi-fw pi-pencil' },


    ];

    const [activeIndex, setActiveIndex] = useState(0);

    const renderOption = () => {
        switch (activeIndex) {
            case 0:
                return <Citas />;
            case 1:
                return <Agenda />;

            case 2:
                return <Recordatorio />;

            default:
                return <Citas />;
        }
    }

    console.log(activeIndex)
    return (
        <>
            <Header />
            <div className="class min-h-screen">
                <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                {renderOption()}
            </div>
        </>
    );
};

