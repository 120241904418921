import { useState } from "react";
import { API } from "../services/api";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Login() {

    const imageUrl = 'https://picsum.photos/1920/1080';



    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };


    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create a request payload with email and password
        const payload = {
            username,
            password,
        };
        console.log(payload);

        try {
            const response = await axios.post(`${API}token/`, payload);
            if (response.status === 200) {
                const data = response.data;
                localStorage.setItem('accessToken', data.access);
                localStorage.setItem('refreshToken', data.refresh);
                localStorage.setItem('user_id', data.user_id);
                navigate('/admin');
                window.location.reload();

            } else {
                setShowError(true);
                setShowCloseButton(true);
                setPassword('');

                console.log('Authentication failed.');
            }
        } catch (error) {
            setShowError(true);
            setShowCloseButton(true);
            setUserName('');
            setPassword('');
            console.error('Error:', error);
        }

    };

    return (
        <div className="bg-cover bg-center bg-fixed" style={{ backgroundImage: `url(${imageUrl})`, }} >
            <div className="h-screen flex justify-center items-center">
                <div className="bg-white mx-4 p-8 rounded shadow-md w-full md:w-1/2 lg:w-1/3">
                    <h1 className="text-3xl font-bold mb-8 text-center">Login</h1>
                    <form onSubmit={handleSubmit}>

                        <div className="divide-y divide-gray-200">
                            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                <div className="relative">
                                    <label className="absolute left-0 -top-3.5 text-gray-600 hover:text-amber-400 text-sm  peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2  peer-focus:text-sm">Usuario</label>

                                    <input
                                        id="email"
                                        name="email"
                                        type="text"
                                        value={username}
                                        onChange={handleUserNameChange}

                                        className="peer placeholder-transparent h-10 w-full 
                                     border-b-2 border-gray-300 text-gray-900 focus:outline-none 
                                     focus:borer-rose-600"  />
                                </div>

                                <div className="relative">
                                    <label className="absolute left-0 -top-3.5 text-gray-600 hover:text-amber-400 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-amber-400 peer-focus:text-sm">Contraseña</label>

                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={password}
                                        onChange={handlePasswordChange}

                                        className="peer placeholder-transparent h-10 w-full border-b-2 
                                        border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        placeholder="Password" />
                                    {/** Muestra la alerta  */}
                                    {showError && (
                                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                            <strong className="font-bold">¡Error!</strong>
                                            <span className="block sm:inline"> Credenciales incorrectas. Por favor, inténtelo de nuevo.</span>
                                            <span
                                                className="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer"
                                                onClick={() => setShowError(false)}
                                            >
                                                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <title>Close</title>
                                                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                                </svg>
                                            </span>
                                        </div>
                                    )}

                                </div>
                                <div className="relative flex justify-center pt-6 ">
                                    <button type="submit" className="bg-lime-500 hover:bg-lime-400 text-white rounded-md px-3 py-2">Ingresar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

