import React from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@nextui-org/react";

export default function CitasModal({ isOpen, citasSolicitadas, cancelarCita, handleCloseModalCitas, verDatosCita }) {
    return (
        <Modal isOpen={isOpen} placement="center" onClose={handleCloseModalCitas}>
            <ModalContent>
                <ModalHeader>Lista de citas</ModalHeader>
                <ModalBody>
                    {citasSolicitadas.length === 0 ? (
                        <p>No hay citas solicitadas.</p>
                    ) : (
                        <ul>
                            {citasSolicitadas.map((citaId, index) => (
                                <li key={citaId}>
                                    <p className="m-2">Cita {index + 1}</p>
                                    <div className="grid ">
                                        <Button
                                            radius="md"
                                            onPress={() => verDatosCita(citaId)}
                                            color="success"
                                            className="text-white"
                                        >
                                            Detalles de la cita
                                        </Button>
                                        <Button
                                            radius="md"
                                            onPress={() => cancelarCita(citaId)}
                                            color="danger"
                                            className="text-white  mt-2"
                                        >
                                            Cancelar cita
                                        </Button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" variant="text" onClick={handleCloseModalCitas}>Cerrar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

