import CardView from "../components/CitasAdmin/Card-View";
import LogoutButton from "../components/Admin/LogoutButton";
import ReloadDataCitas from "../components/Admin/ReloadDataCitas";
export default function Citas() {
    return (

        <>
            <div className="m-4">
                <div className="flex justify-between">
                    <div>
                        <ReloadDataCitas />

                    </div>
                    <div className="order-last">
                        <LogoutButton />
                    </div>
                </div>


            </div>
            <div >
                <CardView />

            </div>
        </>
    );
}