

import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css"
import axios from 'axios';
import { useState, useEffect } from 'react';
import { API } from '../../services/api';
import dayjs from 'dayjs'


export default function CalendarCompAdmin() {

    const localizer = dayjsLocalizer(dayjs)
    const [events, setEvents] = useState([]);

    const convertDate = (date) => {
        return dayjs.utc(date).toDate();
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API}eventos/`);
                console.log(response.data);
                const appointments = response.data.map((appointment) => ({
                    ...appointment,
                    start: convertDate(appointment.start),
                    end: convertDate(appointment.end),
                }));
                setEvents(appointments);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);


    const eventStyleGetter = (event, start, end, isSelected) => {
        let style = {
            backgroundColor: event.ocupado ? 'red' : 'green', // Cambiar a 'red' si ocupado es verdadero
            borderRadius: '0px',
            opacity: 0.8,
            color: 'white',
            border: '1px solid #fff',
            display: 'block'
        };
        return {
            style: style
        };
    };


    return (

        <div style={{
            height: "85vh",
            width: "90vw",
            margin: "0 auto",

            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Calendar
                localizer={localizer}
                events={events}
                views={["month", "week", "day"]}
                defaultView='week'
                step={5}
                min={dayjs('2024-02-02T08:00:00').toDate()}
                max={dayjs('2024-02-02T20:60:00').toDate()}
                timeslots={2}
                eventPropGetter={eventStyleGetter} 

            />

        </div>
    );
}