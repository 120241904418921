import React from "react";
import { useState } from "react";
import {
    Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button, NavbarMenuToggle, NavbarMenu, NavbarMenuItem,

} from "@nextui-org/react";

import logo from "../assets/logo.png"
export default function Header() {

    return (
        <Navbar className="bg-teal-700 text-white ">
            <NavbarContent>

                <NavbarBrand >
                    

                    <p className="font-bold text-inherit">Briza Feet </p>
                </NavbarBrand>
            </NavbarContent>


            <NavbarContent justify="end">

            </NavbarContent>

        </Navbar>
    );
}
