import React from "react";
import { Button, Divider, Accordion, AccordionItem } from "@nextui-org/react";
import { Link } from "react-router-dom";
import Header from "../components/Static/Header";
export default function HomeClient() {

    return (
        <div >
            <Header />
            <section className="  items-center  mt-[50px]">
                <div className="bg-white border drop-shadow-2xl rounded-lg   m-4 ">
                    <div className="p-2 sm:text-4xl  m-4 ">
                        <h1 className="text-green-500">¿Como reservar una cita?</h1>

                    </div>
                    <Divider />

                    <div className="sm:text-2xl m-4">
                        <p>
                            Bienvenido al servicio de citas en línea. Te guiaré para programar una cita en nuestra plataforma. Asegúrate de proporcionar la información necesaria. ¡Comencemos!
                        </p>


                    </div>
                </div>

                <div className="m-4 bg-white border drop-shadow-2xl rounded-lg">
                    <Accordion variant="bordered">
                        <AccordionItem key="1" aria-label="Accordion 1" classNames={{ title: "text-success" }} title="Paso 1: Observa los Colores del Calendario" className="p-2">
                            <Divider />
                            <div className="pt-2">
                                <p className="pt-4">El calendario muestra los horarios disponibles de la siguiente manera:</p>
                                
                                <div className="p-4 flex">
                                    <Button color="success" variant="shadow" className="text-white sm:maxn-w-[110px]">Verde</Button>
                                    <p className="pl-4 md:pt-2">Horarios disponibles para citas.</p>
                                </div>

                                <div className="p-4 flex ">
                                    <Button color="danger" variant="shadow" className="text-white">Rojo</Button>
                                    <p className="pl-4 md:pt-2">Horarios ya ocupados.</p>

                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem key="2" aria-label="Accordion 2" classNames={{ title: "text-success" }} title="Paso 2: Selecciona un Horario Disponible">
                            <Divider />

                            <div className="p-4 ">
                                <p>
                                    Haz clic en cualquier horario que este disponible. Al hacerlo,
                                    se abrirá una ventana emergente donde podrás ingresar la información requerida y
                                    confirmar el horario seleccionado.

                                </p>
                                <p className="pt-4">
                                    Una vez hayas completado el formulario, es crucial verificar la exactitud de tus
                                    datos antes de proceder. Posteriormente, para confirmar tu cita, utiliza el botón
                                    verde ubicado en el formulario.
                                </p>
                            </div>
                        </AccordionItem>

                    </Accordion>
                </div>
                <div className="m-4 bg-white border drop-shadow-2xl rounded-lg min-h-[90px]">
                    <div className="">
                        <div className="p-2 sm:text-2xl  m-4  text-center">
                            <h1 className="text-red-500">
                                Nota

                            </h1>

                        </div>
                        <Divider />
                        <div className="p-4">
                            <p>
                                Si necesitas cancelar tu cita, simplemente busca el botón Azul ubicado en la parte superior del calendario y haz clic en él. Esto te permitirá cancelar tus citas de manera rápida y sencilla.
                            </p>
                        </div>

                    </div>
                </div>
                <div className="m-4 bg-white border drop-shadow-2xl rounded-lg min-h-[90px]">
                    <div className="">
                        <div className="p-2 sm:text-2xl  m-4 ">
                            <h1 className="text-green-500">
                                ¡Listo! Has programado tu cita de manera exitosa.

                            </h1>

                        </div>
                        <Divider />
                        <div className="p-4">
                            <p>
                                Si tienes alguna pregunta, no dudes en ponerte en contacto con nuestro equipo de soporte. ¡Gracias por utilizar nuestro servicio de citas en línea!
                            </p>
                        </div>
                        <div className="flex items-center justify-center m-5">
                            <Link to={`/Horario`}>
                                <Button radius="md" color="success" className="text-white ">
                                    Comenzar
                                </Button>
                            </Link>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
