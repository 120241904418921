
import React, { useState, useRef, useEffect } from "react";
import { API } from "../../services/api";
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Textarea, Input, Button } from "@nextui-org/react";

export default function EditRecordatorio({ isOpen, onOpen, onOpenChange, recordatorioId, onEdit }) {

    const toast = useRef(null);
    const [values, setValues] = useState({
        titulo_nota: '',
        texto_nota: '',
    })



    const loadInitialData = async () => {
        try {
            const response = await axios.get(`${API}recordatorio/${recordatorioId}`);
            setValues({ titulo_nota: response.data.titulo_nota, texto_nota: response.data.texto_nota });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadInitialData();
    }, [recordatorioId]);



    const handleSubmit = (e) => {
        e.preventDefault();
        if (recordatorioId !== undefined) {
            axios.put(`${API}recordatorio/${recordatorioId}/`, values)
                .then(res => {
                    onEdit({ id: recordatorioId, ...values });
                })
                .catch(err => console.log(err));
        } else {
            console.log("recordatorioId is undefined");
        }
    };


    return (
        <>

            <Toast ref={toast} />
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="center"
            >
                <form action="">
                    <form action="">


                        <ModalContent>
                            {(onClose) => (
                                <>
                                    <ModalHeader className="flex flex-col gap-1 items-center justify-center">
                                        Editar Recordatorio
                                    </ModalHeader>
                                    <ModalBody>

                                        <Input
                                            autoFocus
                                            name="titulo"
                                            value={values.titulo_nota}
                                            label="Titulo"
                                            onChange={e => setValues({ ...values, titulo_nota: e.target.value })}
                                            placeholder=""
                                            variant="bordered"
                                        />
                                        <Textarea
                                            name="descripcion"
                                            value={values.texto_nota}
                                            onChange={e => setValues({ ...values, texto_nota: e.target.value })}
                                            variant="bordered"
                                            label="Descripcion"
                                            color="default"
                                        />




                                    </ModalBody>
                                    <ModalFooter className="flex items-center justify-center">
                                        <Button color="success" className="text-white" onClick={handleSubmit} >
                                            Editar
                                        </Button>
                                        <Button color="danger" variant="flat" onPress={onClose}>
                                            Cancelar
                                        </Button>

                                    </ModalFooter>
                                </>
                            )}
                        </ModalContent>
                    </form>
                </form>
            </Modal>
        </>
    );
}
