
import React, { useState, useRef  } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Textarea, Input } from "@nextui-org/react";
import { API } from "../../services/api";
import axios from 'axios';
import { Toast } from 'primereact/toast';


export default function RecordatorioFormulario({ isOpen, onOpen, onOpenChange }) {

    const toast = useRef(null);

    const [titulo_nota, setTitulo_nota] = useState('');
    const [texto_nota, setTexto_nota] = useState('');


    const handleTituloChange = (e) => {
        setTitulo_nota(e.target.value);
    };

    const handleTextChange = (e) => {
        setTexto_nota(e.target.value);
    };

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
    }

   

    const handleCreateRecordatorio = async (e) => {
        e.preventDefault();

        const payload = {
            titulo_nota,
            texto_nota,
        };
        try {
            const response = await axios.post(`${API}recordatorios/`, payload);

            console.log('Recordatorio creado con éxito:', response.data);

            setTitulo_nota('');
            setTexto_nota('');
            showSuccess();
            onOpenChange(false);
            window.location.reload();
        } catch (error) {
            console.error('Error al crear el recordatorio:', error);
        }
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="center"
            >
                <form action="">
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <ModalHeader className="flex flex-col gap-1 items-center justify-center">
                                    Crear Recordatorio
                                </ModalHeader>
                                <ModalBody>



                                    <Input
                                        autoFocus
                                        name="titulo"
                                        value={titulo_nota}
                                        onChange={handleTituloChange}
                                        label="Titulo"
                                        placeholder=""
                                        variant="bordered"
                                    />
                                    <Textarea
                                        name="descripcion"
                                        value={texto_nota}
                                        onChange={handleTextChange}
                                        variant="bordered"
                                        label="Descripcion"
                                        color="default"
                                    />




                                </ModalBody>
                                <ModalFooter className="flex items-center justify-center">
                                    <Button color="success" className="text-white" onClick={handleCreateRecordatorio} >
                                        Crear
                                    </Button>
                                    <Button color="danger" variant="flat" onPress={onClose}>
                                        Cancelar
                                    </Button>

                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </form>
            </Modal>
            <Toast ref={toast} />
        </>
    );
}
