import React, { useState, useEffect } from "react";
import CalendarCompClient from "../components/Agenda/CalendarCompClient";
import { Button } from "@nextui-org/react";
import CitaFormulario from "../components/Formularios/CitaFormulario";
import Header from "../components/Static/Header";
import axios from "axios";
import { API } from "../services/api";
import Swal from 'sweetalert2';
import dayjs from 'dayjs';

import ModalCitasCliente from "../components/ClienteCitas/ModalCitasCliente";

export default function HorarioClient() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenCitas, setIsModalOpenCitas] = useState(false);

    const [isCitaSolicitada, setIsCitaSolicitada] = useState(false); // Nuevo estado
    const [citaId, setCitaId] = useState(null);
    const [hayEventosDisponibles, setHayEventosDisponibles] = useState(false);
    const [eventos, setEventos] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null); // Agrega el estado para el evento seleccionado

    const [citasSolicitadas, setCitasSolicitadas] = useState([]); // Almacena los IDs de las citas solicitadas

    useEffect(() => {
        let citasIds = JSON.parse(localStorage.getItem('citasIds')) || [];

        citasIds.forEach(citaId => {
            verificarCita(citaId);
        });
        cargarCitasSolicitadas();
        cargarEventosSemanales();
    }, []);


    const cargarCitasSolicitadas = () => {
        const citasIds = JSON.parse(localStorage.getItem('citasIds')) || [];
        setCitasSolicitadas(citasIds);
    };
    const cargarEventosSemanales = () => {
        const fechaActual = dayjs();
        const fechaInicioSemana = fechaActual.startOf('week'); // Obtener el comienzo de la semana actual
        const fechaFinSemana = fechaActual.endOf('week'); // Obtener el final de la semana actual

        axios.get(`${API}eventos/`)
            .then(res => {
                const eventosSemanales = res.data.filter(evento =>
                    dayjs(evento.start).isBetween(fechaInicioSemana, fechaFinSemana, null, '[]')
                );

                if (eventosSemanales.length > 0) {
                    setEventos(eventosSemanales);
                    setHayEventosDisponibles(true);
                }
            })
            .catch(error => {
                console.error(error.response.status);
            });
    };



    const handleOpenModal = () => {
        if (hayEventosDisponibles) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(true);
        }
    };

    const handleOpenModalVerCitas = () => {
        setIsModalOpenCitas(true);

    };
    const handleCloseModalCitas = () => {
        setIsModalOpenCitas(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleCitaSolicitada = (citaId) => {
        setIsCitaSolicitada(true);
        setCitaId(citaId);
        localStorage.setItem('citaId', citaId);
    };

    const removeLocalStorage = () => {
        localStorage.removeItem('citaId')
    }
    const removeLocalStorageCitas = () => {
        localStorage.removeItem('citasIds')
    }

    const verificarCita = async (citaId) => {
        try {

            const response = await axios.get(`${API}cita/${citaId}`);
            if (response.status === 200) {
                setIsCitaSolicitada(true);
            } else {
                removeLocalStorage();
                removeLocalStorageCitas();

                setIsCitaSolicitada(false);
                window.location.reload();

            }
        } catch (error) {
            console.error('Error al verificar la cita:', error);
            removeLocalStorage();
            removeLocalStorageCitas();
        }
    };

    const verDatosCita = async (citaId) => {
        try {
            const response = await axios.get(`${API}cita/${citaId}`);

            const citaSeleccionada = response.data;

            const fechaInicio = dayjs(citaSeleccionada.horario.start).locale('es').format('dddd, D [de] MMMM, HH:mm');
            const fechaFin = dayjs(citaSeleccionada.horario.end).format('HH:mm');

            Swal.fire({
                title: 'Detalles de la cita',
                html: `
                <p style="margin: 10px 0;">Nombre: ${citaSeleccionada.nombre}</p>
                <p style="margin: 10px 0;">Apellido: ${citaSeleccionada.apellidos}</p>
                <p style="margin: 10px 0;">Hora: ${fechaInicio} - ${fechaFin}</p>
                `,
                icon: 'info',
                confirmButtonText: 'Cerrar'
            });
        } catch (error) {
            // Maneja cualquier error de la solicitud
            console.error("Error al obtener los datos de la cita:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al obtener los detalles de la cita.'
            });
        }
    };


    const cancelarCita = async (citaId) => {
        try {
            
            const response = await axios.delete(`${API}cita/${citaId}`, {
                  timeout: 5000 // Timeout in milliseconds (default is 0 or no timeout)
            });
          
           if (response.status === 204) {
                const nuevasCitas = citasSolicitadas.filter(id => id !== citaId);
                setCitasSolicitadas(nuevasCitas);
                localStorage.setItem('citasIds', JSON.stringify(nuevasCitas));
                window.location.reload();
            }
       
        } catch (error) {
            console.error('Error al cancelar la cita:', error);
            const nuevasCitas = citasSolicitadas.filter(id => id !== citaId);
            setCitasSolicitadas(nuevasCitas);
            localStorage.setItem('citasIds', JSON.stringify(nuevasCitas));
            window.location.reload();
        }
    };


    const handleSelected = (event) => {
        setSelectedEvent(event); // Actualizar estado con el evento seleccionado
    };

    return (
        <>
            <Header />
            <div className="m-4 md:hidden">
                <h1>Recuerde colocar su dispositivo en posición horizontal para ver los horarios correctamente.</h1>
            </div>
            <div className="m-4 flex items-center justify-center ">

                {citasSolicitadas.length > 0 && (
                    <Button
                        radius="md"
                        color="primary"
                        onClick={handleOpenModalVerCitas}
                    >
                        Ver citas
                    </Button>
                )}
                {isModalOpenCitas && (
                    <ModalCitasCliente  
                        isOpen={isModalOpenCitas}
                        citasSolicitadas={citasSolicitadas}
                        cancelarCita={cancelarCita}
                        handleCloseModalCitas={handleCloseModalCitas}
                        verDatosCita={verDatosCita}
                    />
                )}


            </div>
            <div className="m-4 ">
                <CalendarCompClient onSelectEvent={handleSelected} handleOpenModal={handleOpenModal} />

            </div>
            {isModalOpen && (
                <CitaFormulario
                    isOpen={isModalOpen}
                    onOpen={handleOpenModal}
                    onOpenChange={handleCloseModal}
                    onCitaSolicitada={handleCitaSolicitada}
                    selectedEvent={selectedEvent}
                />
            )}

        </>
    );
}
