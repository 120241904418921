import './App.css';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


// Componentes
import Header from './components/Static/Header';
import HorarioClient from './pages/Horario-Client';
import HomeClient from './pages/Home-Client';

// Componentes Admin
import Admin from './pages/Admin';
import Login from './pages/Login';
import Recordatorio from './pages/Recordatorios';

//Prime react
import "primereact/resources/themes/lara-light-green/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';


function App() {


  const isUserAuthenticated = () => {
    const accessToken = localStorage.getItem('accessToken');
    return accessToken !== null;
  };

  const isAuthenticated = isUserAuthenticated();

  const router = createBrowserRouter([

    {
      path: `/`,

      element: <HomeClient />,
    },
    {
      path: `/Horario`,
      element: <HorarioClient />,
    },

    // Rutas ADMIN
    {
      path: `/admin`,
      element: isAuthenticated ? <Admin /> : <Login />,
    },

    {
      path: `/login`,
      element: <Login/>,
    },


  ]);



  return (
    <>
      <div className='min-h-screen '>

    
   
        <RouterProvider router={router} />

      </div>

    </>
  );
}

export default App;
