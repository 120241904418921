import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardFooter, Divider, Image, Button } from "@nextui-org/react";
import { API } from "../../services/api";
import axios from "axios";
import dayjs from "dayjs";
import 'dayjs/locale/es';

export default function CardView() {

    const [citas, setCitas] = useState([]);
    const [contador, setContador] = useState(0);



    const fetchCitas = async () => {
        try {
            const response = await axios.get(`${API}citas/`);
            const citasWithFormattedDates = response.data.map(cita => {
                return {
                    ...cita,
                    fechaFormato: `${dayjs(cita.horario.start).locale('es').format('dddd, D [de] MMMM, HH:mm')} - ${dayjs(cita.horario.end).format('HH:mm')}`
                }
            });
            citasWithFormattedDates.sort((a, b) => {
                const fechaA = dayjs(a.horario.start);
                const fechaB = dayjs(b.horario.start);
                return fechaA - fechaB;
            });
    
            setCitas(citasWithFormattedDates);
        } catch (error) {
            console.log("Error al obtener las citas.", error);
        }
    };

    const handleEliminarCitaAdmin = async (id) => {
        try {
            await axios.delete(`${API}cita/${id}`)
            localStorage.removeItem('citaId');
            fetchCitas();
            window.location.reload();

        } catch (error) {
            console.error("Error al eliminar la cita", error);
            window.location.reload();

        }
    }
    const handleAtendido = async (id) => {
        try {   
            await axios.delete(`${API}cita/${id}`)
            localStorage.removeItem('citaId');
            fetchCitas();
            window.location.reload();


        } catch (error) {
            console.error("Error al marcar como atendido", error);
            window.location.reload();

        }
    };



    useEffect(() => {

        fetchCitas();
    }, []);

    return (
        <>
            {citas.length === 0 ? (
                <div className="grid place-content-center  mt-[50px]">
                    <div>
                        <h2>No hay citas pendientes</h2>
                    </div>
                </div>
            ) : (
                    <div className="md:grid grid-cols-4 gap-4 m-4">
                        {citas.map((card, index) => (
                            <Card key={card.id} className="max-w-[500px] mt-4 ml-4">
                                <CardHeader className="flex gap-3">

                                    <div className="flex flex-col">
                                        <p className="text-md">{card.nombre}</p>
                                        <p className="text-md">{card.apellidos}</p>
                                    </div>
                                </CardHeader>
                                <Divider />
                                <CardBody >
                                    <p className="pt-2"><strong>Titulo:</strong> {card.horario.title}</p>
                                    <p className="pt-2"><strong>Semana:</strong> {card.horario.nombre_clave}</p>
                                    <p className="pt-2"><strong>Hora:</strong> {card.fechaFormato}</p>
                                    <p className="pt-2"><strong>Celular:</strong> {card.celular}</p>
                                   {/**                                   
                                 *  <p className="pt-2"><strong>Podóloga: </strong>{card.podologo.nombre}</p>
 */}
                                </CardBody>
                                <Divider />
                                <CardFooter className="flex items-center justify-center">
                                    <div className="grid grid-cols-2 gap-4">
                                        <Button radius="md" onClick={() => handleAtendido(card.id)} color="success" className="text-white ">
                                            Atendido
                                        </Button>
                                        <Button radius="md" color="danger" onClick={() => handleEliminarCitaAdmin(card.id)} >
                                            Ausente
                                        </Button>
                                    </div>
                                </CardFooter>
                            </Card>
                        ))}
                    </div>
            )}

        </>
    );

}

