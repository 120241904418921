
import React, { useState } from "react";
import { Button } from "@nextui-org/react";

import LogoutButton from "../components/Admin/LogoutButton";
import CalendarCompAdmin from "../components/Agenda/CalendarCompAdmin";
import HorarioFormulario from "../components/Formularios/HorarioFormulario";
import SemanaFormulario from "../components/Formularios/SemanaFormulario";

export default function Agenda() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);



    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleOpenModal2 = () => {
        setIsModalOpen2(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleCloseModal2 = () => {
        setIsModalOpen2(false);
    };
    return (
        <>
            <div className="m-4">
                <div className="md:flex justify-between border-b-2 md:p-4 shadow-md">
                    <Button radius="md" onPress={handleOpenModal} color="success" className="text-white mb-2">
                        Crear Evento
                    </Button>
                    <Button radius="md" onPress={handleOpenModal2} color="success" className="text-white ml-2 mb-2">
                        Crear Evento Semana
                    </Button>
                    <div className="order-last">
                        <LogoutButton />
                    </div>

                </div>

            </div>
            <div className="m-4 border-b-4 shadow-lg">
                <CalendarCompAdmin />
            </div>

            <HorarioFormulario
                isOpen={isModalOpen}
                onOpen={handleOpenModal}
                onOpenChange={handleCloseModal}
            />

            <SemanaFormulario
                isOpen={isModalOpen2}
                onOpen={handleOpenModal2}
                onOpenChange={handleCloseModal2}
            />

        </>
    );
}