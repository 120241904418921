import React, { useState } from "react";

import { Button } from "@nextui-org/react";

import LogoutButton from "../components/Admin/LogoutButton";
import CardViewRecordatorios from "../components/RecordatoriosAdmin/CardViewRecordatorios";
import RecordatorioFormulario from "../components/Formularios/RecordatorioFormulario";

export default function Recordatorio() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>


            <div className="m-4">
                <div className="flex justify-between">
                    <div>
                        <Button radius="md" onPress={handleOpenModal} color="success" variant="shadow" className="text-white ">
                            Crear Recordatorio
                        </Button>
                    </div>
                    <div className="order-last">
                        <LogoutButton />
                    </div>

                </div>
            </div>
            <div className=" p-2 ">

                <CardViewRecordatorios />

            </div>

            <RecordatorioFormulario
                isOpen={isModalOpen}
                onOpen={handleOpenModal}
                onOpenChange={handleCloseModal} 
            />
        </>
    );
}