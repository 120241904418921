import React from "react";
import { Button } from "@nextui-org/react";


const ReloadDataCitas = () => {

    const HandleReload = () => {

        window.location.reload();
    };

    return (

        <Button color="primary" onClick={HandleReload}>
            Actualizar
        </Button>
    );
};

export default ReloadDataCitas;