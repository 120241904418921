
import React, { useState, useRef } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Switch, Checkbox, Input, Link } from "@nextui-org/react";
import { API } from "../../services/api";
import axios from 'axios';
import { Toast } from 'primereact/toast';

export default function HorarioFormulario({ isOpen, onOpen, onOpenChange }) {
    const toast = useRef(null);
    const [title, setTitle] = useState('');
    const [nombre_clave, setNombre_clave] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [ocupado, setOcupado] = useState(false);


    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleNombreClaveChange = (e) => {
        setNombre_clave(e.target.value);
    };

    const handleStartChange = (e) => {
        setStart(e.target.value);
    };

    const handleEndChange = (e) => {
        setEnd(e.target.value);
    };

    const handleSwitchChange = () => {
        setOcupado(!ocupado);
    };

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
    }


    const handleCreateHorario = async (e) => {
        e.preventDefault();

        const payload = {
            title,
            start,
            end,
            ocupado,
            nombre_clave,
        };
        try {
            const response = await axios.post(`${API}eventos/`, payload);

            console.log('Horario creado con éxito:', response.data);

            setTitle('');
            setStart('');
            setEnd('');
            setNombre_clave('');
            setOcupado(false); // Restablecer el estado ocupado
            showSuccess();
            onOpenChange(false);
            window.location.reload();
        } catch (error) {
            console.error('Error al crear el horario:', error);
        }
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="center"
            >
                <form action="">
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <ModalHeader className="flex flex-col gap-1 items-center justify-center">
                                    Crear Horario
                                </ModalHeader>
                                <ModalBody>
                                    <Input
                                        autoFocus
                                        label="Titulo"
                                        placeholder="Titulo"
                                        value={title}
                                        onChange={handleTitleChange}
                                        variant="bordered"
                                    />
                                    <Input
                                        autoFocus
                                        label="Nombre Clave"
                                        placeholder="Nombre Clave"
                                        value={nombre_clave}
                                        onChange={handleNombreClaveChange}
                                        variant="bordered"
                                    />
                                    <label>
                                        Ocupado:
                                        <div className="flex items-center gap-2">
                                            <Switch
                                                isSelected={ocupado}
                                                onValueChange={handleSwitchChange}
                                            >
                                                Estado
                                            </Switch>
                                            <p className="text-small text-default-500">
                                                {ocupado ? 'Ocupado' : 'No ocupado'}
                                            </p>
                                        </div>
                                    </label>
                                    <Input

                                        label="Fecha de Inicio"
                                        placeholder="Fecha Final"
                                        value={start}
                                        onChange={handleStartChange}
                                        type="datetime-local"
                                        variant="bordered"
                                    />
                                    <Input

                                        label="Fecha de Inicio"
                                        placeholder="Fecha Final"
                                        value={end}
                                        onChange={handleEndChange}
                                        type="datetime-local"
                                        variant="bordered"
                                    />

                                </ModalBody>
                                <ModalFooter className="flex items-center justify-center">
                                    <Button color="success" className="text-white" onClick={handleCreateHorario}>
                                        Crear
                                    </Button>
                                    <Button color="danger" variant="flat" onPress={onClose}>
                                        Cancelar
                                    </Button>

                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </form>
            </Modal>
            <Toast ref={toast} />
        </>
    );
}
