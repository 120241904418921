import React, { useState, useEffect } from "react";
import {
    Card, CardHeader, CardBody, CardFooter, Divider, Image, Button,
    Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Textarea, Input,

} from "@nextui-org/react";

import axios from "axios";
import { API } from "../../services/api";
import EditRecordatorio from "./EditRecordatorio";

export default function CardViewRecordatorios() {


    const [recordatorios, setRecordatorios] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecordatorioId, setSelectedRecordatorioId] = useState();



    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };




    const fetchRecordatorios = async () => {
        try {
            const response = await axios.get(`${API}recordatorios/`);
            setRecordatorios(response.data);
        } catch (error) {
            console.log("Error al obtener los recordatorios", error);
        }
    };


    const handleEliminar = async (id) => {
        try {
            await axios.delete(`${API}recordatorio/${id}`);
            // Actualizar el estado después de la eliminación
            fetchRecordatorios();
             window.location.reload();

        } catch (error) {
            console.error("Error al eliminar el recordatorio", error);
            window.location.reload();

        }
    };


    const handleEditView = async (id) => {
        setSelectedRecordatorioId(id);
        handleOpenModal();
    };

    const handleEditRecordatorio = (editedRecordatorio) => {
        // Actualizar el estado local con el recordatorio editado
        setRecordatorios((prevRecordatorios) =>
            prevRecordatorios.map((recordatorio) =>
                recordatorio.id === editedRecordatorio.id ? editedRecordatorio : recordatorio
            )
        );
        handleCloseModal();
    };

    useEffect(() => {
        // Lógica para cargar los datos iniciales
        fetchRecordatorios();
    }, []);



    return (
        <>
            {recordatorios.length === 0 ? (
                <div className="grid place-content-center  mt-[50px]">
                    <div>
                        <h2>No hay recordatorios</h2>
                    </div>
                </div>
            ) : (
                <div className="sm:grid gap-4 grid-cols-2">
                    {recordatorios.map((card, index) => (

                        <Card key={card.id} className="sm:max-w-[610px] mt-4 sm:ml-8 ">
                            <CardHeader className="flex gap-3">
                                <Image
                                    alt="nextui logo"
                                    height={40}
                                    radius="sm"
                                    src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                                    width={40}
                                />

                                <div className="flex flex-col">
                                    <p className="text-md">{card.titulo_nota}</p>
                                </div>
                            </CardHeader>
                            <Divider />
                            <CardBody >
                                <p className="pt-2">{card.texto_nota}</p>
                            </CardBody>
                            <Divider />
                            <CardFooter className="flex items-center justify-center">
                                <div className="grid grid-cols-2 gap-4">
                                    <Button className="text-white" radius="md" color="success" onPress={handleOpenModal} onClick={() => handleEditView(card.id)}  >
                                        Editar
                                    </Button>
                                    <Button radius="md" color="danger" onClick={() => handleEliminar(card.id)}>
                                        Eliminar
                                    </Button>
                                </div>
                            </CardFooter>


                        </Card>

                    ))}
                </div>


            )}
            {selectedRecordatorioId !== undefined && (
                <EditRecordatorio
                    isOpen={isModalOpen}
                    onOpen={handleOpenModal}
                    onOpenChange={handleCloseModal}
                    recordatorioId={selectedRecordatorioId}
                    onEdit={handleEditRecordatorio}
                />
            )}
        </>

    );

}

